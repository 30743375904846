require("dotenv").config();
/* eslint-disable max-len */

module.exports = {
  // default locale is the first one
  locales: [
    /* @intl-code-template '${lang}-${COUNTRY}', */
    "en-US",
    "es",
    "fr-FR",
    "ru-RU",
    "ja-JP",
    "id-ID",
    "hr-HR",
    "zh-CN",
    "sv-SE",
    "ar"
    /* @intl-code-template-end */
  ],

  // Node.js app
  port: process.env.PORT || 3000,

  // https://expressjs.com/en/guide/behind-proxies.html
  trustProxy: process.env.TRUST_PROXY || "loopback",

  // SITE URL
  siteUrl: process.env.SITE_URL || "https://www.snow.limo",

  // API Gateway
  api: {
    // API URL to be used in the client-side code
    clientUrl: process.env.API_CLIENT_URL,
    // API URL to be used in the server-side code
    serverUrl:
      process.env.API_SERVER_URL ||
      `http://localhost:${process.env.PORT || 3000}`,

    apiEndpoint: process.env.API_ENDPOINT_URL || "https://snow.limo/api",
    socketUrl: process.env.SOCKET_URL || "https://www.snow.limo"
  },

  // Database
  databaseUrl:
    "mysql://snow-limo:AVNS_Q0ziJMfF7jVM-fg9Hcp@snow-limo-do-user-15333170-0.c.db.ondigitalocean.com:25060/defaultdb?ssl-mode=REQUIRED",

  // Licence Upload
  licenseuploadDir: process.env.LICENSE_UPLOAD_DIR || "/images/license/",

  // Vehicle Upload
  vehicleUploadDir: process.env.VEHICLE_UPLOAD_DIR || "/images/vehicle/",

  // Profile photo upload
  profilePhotouploadDir:
    process.env.PROFILE_PHOTO_UPLOAD_DIR || "/images/avatar/",

  // category photo upload
  categoryUploadDir:
    process.env.CATEGORY_PHOTO_UPLOAD_DIR || "/images/category/",

  // Logo photo upload
  logoUploadDir: process.env.LOGO_PHOTO_UPLOAD_DIR || "/images/logo/",

  // homepage photo upload
  homepageUploadDir: process.env.HOMEPAGE_UPLOAD_DIR || "/images/homepage/",

  // staticpage photo upload
  staticpageUploadDir:
    process.env.STATICPAGE_UPLOAD_DIR || "/images/staticpage/",

  //Content page photo upload
  contentPageUploadDir:
    process.env.CONTENTPAGE_UPLOAD_DIR || "/images/contentPage/",

  // favicon upload
  faviconUploadDir: process.env.FAVICON_UPLOAD_DIR || "/images/favicon/",

  // tripTone upload
  tripToneUploadDir:
    process.env.TRIPTONE_PHOTO_UPLOAD_DIR || "/images/tripTone/",

  //Promocode image directoy
  promoCodeImageUploadDir: "/images/promocode/",

  //Google map api key
  googleMapAPI: "AIzaSyACcU-T42SJEn5hnNhkc07tnBA8nZhZeME",
  googleMapServerKey: process.env.GOOGLE_MAP_SERVER_KEY,

  // Web analytics
  analytics: {
    // https://analytics.google.com/
    googleTrackingId: process.env.GOOGLE_TRACKING_ID // UA-XXXXX-X
  },

  // Authentication
  auth: {
    jwt: { secret: process.env.JWT_SECRET },

    // https://developers.facebook.com/
    facebook: {
      id: process.env.FACEBOOK_APP_ID,
      secret: process.env.FACEBOOK_APP_SECRET
    },

    // https://cloud.google.com/console/project
    google: {
      id: process.env.GOOGLE_CLIENT_ID,
      secret: process.env.GOOGLE_CLIENT_SECRET
    },

    // https://apps.twitter.com/
    twitter: {
      key: process.env.TWITTER_CONSUMER_KEY,
      secret: process.env.TWITTER_CONSUMER_SECRET
    }
  },
  unitTypes: {
    km: { value: "metric", convertFromMeter: 1000 },
    mile: { value: "imperial", convertFromMeter: 1609 }
  }
};
